*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Raleway', serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-optical-sizing: auto;
  color: #212529;
  font-weight: 400;
  font-size: 1rem;
}

html {
  background-color: #F8F9FA;
  padding: 0px 30px;
}

ul {
  list-style-position: outside;
  margin-left: 20px;
}

li {
  margin: 5px 0;
}

hr {
  border: 0;
  height: 1px;
  background: #D3D3D3;
  margin: 5px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.header-items {
  display: flex;
  gap: 20px;
  color: "#000000";
  font-weight: 500;
}

a {
  color: inherit;
  text-decoration: none;
  height: fit-content;
}

.section {
  margin: 15px 0;
  padding: 10px;
  border: 1px solid #D3D3D3;
  border-radius: 20px;
  /* min-height: 95vh; */
}

.header-with-gap {
  display: flex;
  justify-content: space-between;
  align-items: left;
}

.center {
  align-items: center;
  text-align: center;
  justify-content: center;
  align-content: center;
}

.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.box {
  gap: 10px;
  padding: 10px;
}

.underline-with-primary {
  width: fit-content;
  border-bottom: 1px solid #f3a948;
}

.btn {
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: 0.3s ease;
}

.btn-primary {
  background-color: #f3a948;
  color: white;
}

.btn-primary:hover {
  background-color: #ef8f2f;
}

.btn-secondary {
  background-color: #ADB5BD;
  color: white;
}

.btn-secondary:hover {
  background-color: #939aa1;
}

.btn-linkedin {
  background-color: #0077B5;
  color: white;
}

.btn-linkedin:hover {
  background-color: #005f8b;
}